.navbar {
    /* width: 100%; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* box-sizing: content-box; */
    background: #D97706;
}

.main-content {
    width: 1100px;
    /* background-image: linear-gradient(#FFFBEB, #FFFFFF); */
}

.navbar-middletext {
    display: flex;
    align-items: center;
}